import { api } from "@/services/axios"

export default {

    async updateAttendanceStatusAction({dispatch, commit}, payload){
        commit('errorsMutation', [], { root: true })
        return await new Promise((resolve, reject) => {
            api.put('/instructor/attendance/update/checked', payload).then(res => {
                dispatch('updateCourseUpdatedAt', payload.course_id )
                dispatch('showCourseAction', payload.course_id)
                resolve(res)
            }).catch(err => {
                commit(
                    'errorsMutation', 
                    err.response.data.message, 
                    { root: true }
                )
                reject(err.response.data.message)
            })
        })
    },
    async exportingData({ commit }, { course_id, selected_users }) {
        commit('errorsMutation', [], { root: true });
        try {
            // Make the API call to trigger the export
            const response = await api.get(`/instructor/export/${course_id}`, {
                params: {
                    query: selected_users
                },
                responseType: 'blob' // Important: Expect a file (blob) as the response
            });
    
            // Create a download link and trigger the download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `export-${new Date().toISOString().slice(0, 19).replace(/:/g, '-')}.csv`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
            return response;
        } catch (error) {
            commit('errorsMutation', error.response?.data?.message || 'Unknown error', { root: true });
            throw new Error(error.response?.data?.message || 'Failed to export data');
        }
    }
    
    
    
    
}