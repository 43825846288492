import { api } from "@/services/axios";

export default {
  async getAllCoursesAction({ commit, dispatch }, payload) {
    commit("errorsMutation", [], { root: true });
    return await new Promise((resolve, reject) => {
      api
        .get("/instructor/courses/all")
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("errorsMutation", err.response.data.errors, { root: true });
          reject(err.response.data.errors);
        });
    });
  },

  async addCourseAction({ commit, dispatch }, payload) {
    commit("errorsMutation", [], { root: true });
    return await new Promise((resolve, reject) => {
      api
        .post("/instructor/courses", payload)
        .then((res) => {
          dispatch("addModuleAction", {
            uploader_id: res.data.data.uploader_id,
            course_id: res.data.data.id,
          });
          commit("courseMutation", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("errorsMutation", err.response.data.errors, { root: true });
          reject(err.response.data.errors);
        });
    });
  },

  async getCoursesAction({ commit }, payload) {
    let endpoint = `/instructor/courses`;
    if (payload) {
      endpoint = `/instructor/courses?status=${payload}`;
    }
    await api
      .get(endpoint)
      .then((res) => {
        commit("coursesMutation", res.data.data);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors, {
          root: true,
        });
      });
  },

  async getDashboardCoursesAction({ commit }, payload) {
    commit("errorsMutation", [], { root: true });
    return await new Promise((resolve, reject) => {
      api
        .post("/instructor/courses/dashboard", payload)
        .then((res) => {
          commit("coursesMutation", res.data.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("errorsMutation", err.response.data.errors, { root: true });
          reject(err.response.data.errors);
        });
    });
  },

  async getPublishedCoursesAction({ commit }, payload) {
    commit("errorsMutation", [], { root: true });
    return await new Promise((resolve, reject) => {
      api
        .post("/instructor/courses/published", payload)
        .then((res) => {
          commit("coursesMutation", res.data.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("errorsMutation", err.response.data.errors, { root: true });
          reject(err.response.data.errors);
        });
    });
  },

  async getUnpublishedCoursesAction({ commit }, payload) {
    commit("errorsMutation", [], { root: true });
    return await new Promise((resolve, reject) => {
      api
        .post("/instructor/courses/unpublished", payload)
        .then((res) => {
          commit("coursesMutation", res.data.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("errorsMutation", err.response.data.errors, { root: true });
          reject(err.response.data.errors);
        });
    });
  },

  async getDraftsCoursesAction({ commit }, payload) {
    commit("errorsMutation", [], { root: true });
    return await new Promise((resolve, reject) => {
      api
        .post("/instructor/courses/draft", payload)
        .then((res) => {
          commit("coursesMutation", res.data.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("errorsMutation", err.response.data.errors, { root: true });
          reject(err.response.data.errors);
        });
    });
  },

  async updateCourseDescriptionAction({ commit, dispatch }, payload) {
    commit("errorsMutation", [], { root: true });
    console.log(payload)
    return await new Promise((resolve, reject) => {
      api
        .put(`/instructor/courses/${payload.id}`, payload)
        .then((res) => {
          commit("courseUpdatedAtMutation", new Date());
          resolve(res.data.data);
        })
        .catch((err) => {
          commit("errorsMutation", err.response.data.errors, { root: true });
          reject(err.response.data.errors);
        });
    });
    // await api.put(`/instructor/courses/${payload.id}`, payload)
    // .then(() => {
    //   commit('courseUpdatedAtMutation', new Date())
    // })
    // .catch(err => {
    //   commit('errorsMutation', err.response.data.errors, {
    //     root: true
    //   })
    // })
  },

  async showCourseAction({ commit }, payload) {
    await api
      .get(
        `/instructor/courses/${payload.id}?page=${payload.page}&query=${payload.query}`,
      )
      .then((res) => {
        // console.log(res.data.data);
        commit("courseMutation", res.data.data);
        commit("courseEvaluationsMutation", res.data.data.course_evaluations);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors, {
          root: true,
        });
      });
  },

  async statusCourseAction({ commit, dispatch }, payload) {
    await api
      .put(`/instructor/course/${payload.id}/status`, payload)
      .then((res) => {
        dispatch("getCoursesAction");
        dispatch("showCourseAction", payload.id);
        dispatch("getAssessmentsAction", {
          course_id: payload.id,
        });
        dispatch("getClassesAction");
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors, {
          root: true,
        });
      });
  },

  async deleteCourseAction({ commit, dispatch }, payload) {
    await api
      .delete(`/instructor/courses/${payload.id}`)
      .then((res) => {
        dispatch("getCoursesAction");
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors, {
          root: true,
        });
      });
  },

  async uploadThumbnailCourseAction({ commit, dispatch }, payload) {
    commit("errorsMutation", [], { root: true });
    return await new Promise((resolve, reject) => {
      api
        .post(`/instructor/course/${payload.id}/upload-image`, payload.form)
        .then((res) => {
          commit("courseUpdatedAtMutation", new Date());
          dispatch("updateCourseUpdatedAt", payload.id);
          dispatch("showCourseAction", payload.id);
          resolve(res.data.message);
        })
        .catch((err) => {
          commit("errorsMutation", err.response.data.errors, { root: true });
          reject(err.response.data.errors);
        });
    });
  },

  async tagClassCourseAction({ commit, dispatch }, payload) {
    await api
      .put(`/instructor/course/${payload.id}/class-tags`, payload)
      .then((res) => {
        // dispatch('updateCourseUpdatedAt', payload.id )
        commit("courseUpdatedAtMutation", new Date());
        console.log(res.data.data);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors, {
          root: true,
        });
      });
  },

  async getCourseModulesAction({ commit }, payload) {
    await api
      .get(`/instructor/course/${payload}/modules`, payload)
      .then((res) => {
        commit("modulesMutation", res.data.data);
        console.log(res.data.data);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors, {
          root: true,
        });
      });
  },

  async updateCourseUpdatedAt({ commit, dispatch }, payload) {
    await api
      .put(`/instructor/course/${payload}/updated-at`)
      .then((res) => {
        commit("courseUpdatedAtMutation", new Date());
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors, {
          root: true,
        });
      });
  },

  async getCourseSharedInstructors({ commit }) {
    await api.get("/instructor/share/course/users").then((res) => {
      commit("instructorsMutation", res.data.data);
    });
  },

  async shareCourseAction({ commit, dispatch }, payload) {
    await api
      .put(`/instructor/share/course`, payload)
      .then((res) => {
        dispatch("getInstructors");
        dispatch("showCourseAction", payload.course_id);
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors, {
          root: true,
        });
      });
  },

  async updatePermissionAction({ commit, dispatch }, payload) {
    await api
      .put(`/instructor/share/course/permission`, payload)
      .then((res) => {
        dispatch("getInstructors");
      })
      .catch((err) => {
        commit("errorsMutation", err.response.data.errors, {
          root: true,
        });
      });
  },

  async removeCourseImage({ commit, dispatch }, payload) {
    commit("errorsMutation", [], { root: true });
    return await new Promise((resolve, reject) => {
      api
        .delete(`/instructor/course/${payload.id}/delete-image`)
        .then((res) => {
          commit("courseUpdatedAtMutation", new Date());
          dispatch("updateCourseUpdatedAt", payload.id);
          dispatch("showCourseAction", payload.id);
          resolve(res.data.message);
        })
        .catch((err) => {
          commit("errorsMutation", err.response.data.errors, { root: true });
          reject(err.response.data.errors);
        });
    });
  },
};
