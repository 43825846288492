import { api } from '@/services/axios'

export default {
  async enrollStudentAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    const config = { 
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
      }
    }
    return await new Promise((resolve, reject) => {
      api.post('/instructor/manual/enrollment', payload, config)
      .then(() => {
        dispatch('getEnrolledStudentsAction')
        resolve('yes')
      }).catch(err => {
        commit('errorsMutation', err.response.data.errors, { 
          root: true 
        })
        reject(err.response.data)
      })
    })

    // await api.post('/instructor/manual/enrollments', payload, config)
    // .then(res => {

    // })
    // .catch(err => {
    //   commit('errorsMutation', err.response.data.errors, { 
    //     root: true 
    //   })
    // })
  },
  async getEnrolledStudentsAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/instructor/manual/enrollments?page=${payload.page}&paginate=${payload.paginate}`, payload)
        .then((res) => {
          commit('enrollStudentsMutation', res.data.data.data)
          resolve(res.data.data)
        })
        .catch(err => {
          commit('errorsMutation', err.response, { 
            root: true 
          })
        })
    })
  },
  async getEnrolledStudentAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/instructor/manual/enrollment/user/${payload}`)
        .then((res) => {
          resolve(res.data.data)
        })
        .catch(err => {
          commit('errorsMutation', err.response, { 
            root: true 
          })
        })
    })
  },
}