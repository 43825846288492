import { api } from '@/services/axios'
import { certificatesMutation } from '../mutations';

export default {
  async getCoursesAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post('/user/courses', payload).then(res => {
        commit('coursesMutation', res.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async setCourseLog({commit}, payload) {
    return await new Promise((resolve, reject) => {
      api.post(`/user/course/setlog`, payload ).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async showCourseAction({commit}, payload) {
    return await new Promise((resolve, reject) => {
      api.get(`/user/courses/${payload}`).then(res => {
        let course = res.data.data
        if(course.modules.length>0) {
          course.modules.forEach(module => {
            let data = []
            if(module.topics.length>0) {
              module.topics.forEach(topic => {
                topic['is']='topic'
                data.push(topic)
                if(topic.sub_topics.length>0) {
                  topic.sub_topics.forEach(sub => {
                    sub['is']='sub-topic'
                    data.push(sub)
                  })
                }
              })
              module['data'] = data
            }
          });  
        }
        commit('objectMutation', res.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async showNextContentAction({commit, dispatch}, payload) {
    await api.post(`/user/course/progress/controls`, payload)
    .then(res => {
      commit('materialMutation', res.data.data)
      dispatch('getCoursesAction')
    })
    .catch((err) => {
      console.log(err);
    });
  },

  async updateCourseColor({commit, dispatch}, payload) {
    await api.post(`/user/course-card/color`, payload)
    .then(res => {
      dispatch('getCoursesAction')
    }).catch(err => {
      commit('errorsMutation', err.response.data.errors, { 
        root: true 
      })
    })
  },

  async updateCourseFavorite({commit, dispatch}, payload) {
    await api.post(`/user/course/favorite`, payload)
    .then(res => {
      dispatch('getCoursesAction')
    }).catch(err => {
      commit('errorsMutation', err.response.data.errors, { 
        root: true 
      })
    })
  },

  async generateCertificateAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/generate/certificate`, payload).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        console.log(err)
        reject(err)
      })
    })
  },

  async getTransactionHistory({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/transaction-history/invoices?page=${payload.page}&paginate=${payload.paginate}`, payload).then(res => {
        let _arr = []
        res.data.data.data.forEach(_course => {
          _arr.push({
            id: _course.id,
            course_id: _course.course.id,
            course_uuid: _course.course.uuid,
            user_id: _course.enrollee.id,
            email: _course.enrollee.email,
            reference_id: _course.reference_id,
            title: _course.course.title ? _course.course.title : 'Untitled',
            status: _course.status,
            date: _course.created_at,
            amount: _course.course.price,
            uuid: _course.course.uuid,
            nomination_form: _course.nomination_form,
            image: _course.image,
            proof_of_payment: _course.proof_of_payment,
            mode_of_payment: _course.mode_of_payment,
            official_receipt_no: _course.official_receipt_no,
            date_enrolled: _course.status === 1 ? _course.updated_at : '',
          })
        })
        commit('transactionHistoryMutation', _arr)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async uploadProofOfPayment({commit}, payload){
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/upload/nomination-form/invoice/${payload.invoice_id}`, payload.form, { headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
      }}).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        console.log(err)
        reject(err)
      })
    })
  },

  async getCourseCertificatesAction({commit},  payload){
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/certificates`, payload).then(res => {
        commit('certificatesMutation', res.data.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async downloadCourseCertificateAction({commit},  payload){
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/download/certificate`, payload).then(res => {
        resolve(res.data.downloadable_url)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async markCourseAsCompletedAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/course/mark-as-complete`, payload).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async getCompletedCoursesAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/user/complete-courses`, payload).then(res => {
        let courses = []
        res.data.data.enrollments.forEach((course) => {
          if(course.course){
            courses.push(course)
          }
        })
        commit('completedCoursesMutation', courses)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async getClassCategoryAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })

    return await new Promise((resolve, reject) => {
      api.get(`/user/course/class-categories`).then(res => {
        commit('classesMutation', res.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err)
      })
    })
  },

  async courseCheckout({commit}, payload) {
    commit('errorsMutation', [], { root: true })

    const config = { 
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
      }
    }

    return await new Promise((resolve, reject) => {
      api.post(`/user/checkout`, payload, config).then(res => {
        resolve(res.data.data)
      }).catch(err => {
        if(err.response.data.errors) {
          commit(
            'errorsMutation', 
            err.response.data.errors, 
            { root: true }
          )
        }
        reject(err.response.data.message)
      })
    })
  },

  async courseProofOfPaymentAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })

    const config = { 
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data',
      }
    }

    return await new Promise((resolve, reject) => {
      api.post(`/user/upload/proof-of-payment/invoice`, payload, config).then(res => {
        resolve(res.data.data)
      }).catch(err => {

        console.log(err)
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.message)
      })
    })
  }
}