import { api } from '@/services/axios'

export default {
  // async getEnrolleesAction({commit}) {
  //   await api.get('/admin/manual/enrollments')
  //   .then(res => {
  //     // console.log(res.data);
  //     commit('enrolleesMutation', res.data.data)
  //   })
  //   .catch(err => {
  //     commit(
  //       'errorsMutation', 
  //       err.response.data.errors, 
  //       { root: true }
  //     )
  //   })
  // },

  async getEnrolleesAction({commit}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/admin/enrollment/courses?page=${payload.page}&paginate=${payload.paginate}&search=${payload.search}`).then(res => {
        res.data.data.data.forEach(element => {
          element.uploader.name = `${element.uploader.first_name ? element.uploader.first_name : 'Instructor'} ${element.uploader.last_name ? element.uploader.last_name : 'Account'}`
        });
        commit('enrolleesMutation', res.data.data.data)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },
  async getCourseEnrolleesAction({commit}, payload) {
    commit('enrolleesMutation', [])
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.get(`/admin/enrollment/course/${payload.course_uuid}/enrollees`, {params: payload }).then(res => {
        let enrollees = []
        res.data.data.enrollees.forEach(element => {
          element.invoices.forEach(invoice => {
            let enrollee = {}
            enrollee.user_id = element.id
            enrollee.email = element.email
            enrollee.name = `${element.first_name && element.first_name} ${element.last_name && element.last_name}`
            enrollee.invoice_id = invoice.id
            enrollee.enrolled_by = invoice.enroll_by ? `${invoice.enroll_by.first_name} ${invoice.enroll_by.last_name}` : ''
            enrollee.date_enrolled = invoice.created_at
            enrollee.invoice_status = invoice.status
            enrollee.date_approved = invoice.updated_at
            enrollee.official_receipt = invoice.image
            enrollee.nomination_form = invoice.nomination_form
            enrollee.proof_of_payment = invoice.proof_of_payment
            enrollee.mode_of_payment = invoice.mode_of_payment
            enrollee.reference_id = invoice.reference_id
            enrollee.course_id = invoice.course_id
            enrollee.amount = invoice.amount
            enrollees.push(enrollee)
          })
        });
        commit('enrolleesMutation', enrollees)
        resolve(res.data.data)
      }).catch(err => {
        commit(
          'errorsMutation', 
          err.response.data.errors, 
          { root: true }
        )
        reject(err.response.data.errors)
      })
    })
  },
  async updateInvoiceStatus({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.put(`/admin/enrollment/invoice/status`, payload ).then(res => {
        commit(
          'alertMutation',
          {
            show: true,
            text: "Successfully updated!",
            type: "success"
          },
          { root: true }
        )
        resolve('ye')
      })
      .catch(errs => {
        // console.log(errs.response.data.message)
        commit(
          'alertMutation',
          {
            show: true,
            text: errs.response.data.message,
            type: "error"
          },
          { root: true }
        )
        reject(errs.response.data.message)
      })
    })
  },

  async lbpStatusCheckAction({commit, dispatch}, payload) {
    commit('errorsMutation', [], { root: true })
    return await new Promise((resolve, reject) => {
      api.post(`/admin/lbpStatus/check`, payload ).then(res => {

        if (res.data.data) {
          let { status } = res.data.data
          commit(
            'alertMutation',
            {
              show: true,
              text: `Status: ${status === 1 ? 'SUCCESS' : 'PENDING'}`,
              type: "success"
            },
            { root: true }
          )
        } else {
          commit(
            'alertMutation',
            {
              show: true,
              text: `Status: PENDING`,
              type: "success"
            },
            { root: true }
          )
        }
        resolve(res.data.data ? res.data.data.status : 2)
      })
      .catch(errs => {
        // console.log(errs.response.data.message)
        commit(
          'alertMutation',
          {
            show: true,
            text: errs.response.data.message,
            type: "error"
          },
          { root: true }
        )
        reject(errs.response.data.message)
      })
    })
  }
}
