import { middleware } from './middleware'

export const TeacherRoutes = [
  {
    path: '/instructor',
    component: () => import('../layouts/Main.vue'),
    meta: { middleware: [middleware] },
    redirect: { name: 'Instructor Dashboard' },
    children: [
      {
        path: '/instructor/dashboard',
        name: 'Instructor Dashboard',
        component: () => import('../views/teacher/Dashboard.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Dashboard',
          collapse: false,
        },
      },
      {
        path: '/instructor/my-courses/',
        name: 'Instructor My Courses',
        component: () => import('../views/teacher/Courses.vue'),
        redirect: { name: 'Instructor Uploaded Course' },
        meta: { 
          middleware: [middleware],
          parent: 'Instructor My Courses',
          collapse: true,
        },
        children: [
          {
            path: 'published',
            name: 'Instructor Published Course',
            component: () => import('../views/teacher/Published.vue'),
            meta: { 
              middleware: [middleware],
              parent: 'Instructor My Courses',
              collapse: true,
            },
          },
          {
            path: 'unpublished',
            name: 'Instructor Unpublished Course',
            component: () => import('../views/teacher/Unpublished.vue'),
            meta: { 
              middleware: [middleware],
              parent: 'Instructor My Courses',
              collapse: true,
            },
          },
          {
            path: 'drafts',
            name: 'Instructor Drafts Course',
            component: () => import('../views/teacher/Drafts.vue'),
            meta: { 
              middleware: [middleware],
              parent: 'Instructor My Courses',
              collapse: true,
            },
          },
          {
            path: ':status/manage/:id',
            name: 'Instructor Manage Course',
            component: () => import('../views/teacher/CourseManager.vue'),
            meta: { 
              middleware: [middleware],
              parent: 'Instructor My Courses',
              collapse: true,
            },
          },
        ]
      },
      {
        path: '/instructor/assessments/',
        name: 'Instructor Assessment',
        component: () => import('../views/teacher/AssessmentBank.vue'),
        redirect: { name: 'Instructor Assessment Bank' },
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Assessment',
          collapse: false,
        },
        children: [
          {
            path: 'bank',
            name: 'Instructor Assessment Bank',
            component: () => import('../views/teacher/AssessmentBuilder.vue'),
            meta: { 
              middleware: [middleware],
              parent: 'Instructor Assessment',
              collapse: false,
            },
          },
          {
            path: 'rubrics',
            name: 'Instructor Assessment Rubrics',
            component: () => import('../views/teacher/AssessmentRubrics.vue'),
            meta: { 
              middleware: [middleware],
              parent: 'Instructor Assessment',
              collapse: false,
            },
            children: [
              {
                path: ':id/edit',
                name: 'Instructor Rubric Edit',
                component: () => import('../views/teacher/RubricEdit.vue'),
                meta: { 
                  middleware: [middleware],
                  parent: 'Instructor Assessment',
                  collapse: false,
                },
              },
              {
                path: ':id/view',
                name: 'Instructor Rubric View',
                component: () => import('../views/teacher/RubricView.vue'),
                meta: { 
                  middleware: [middleware],
                  parent: 'Instructor Assessment',
                  collapse: false,
                },
              }
            ]
          },
        ]
      },
      {
        path: '/instructor/grading',
        name: 'Instructor Grading',
        component: () => import('../views/teacher/Grading.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Grading',
          collapse: false,
        },
      },
      {
        path: '/instructor/grading/:id/survey/:type/:survey_id',
        name: 'Instructor Survey',
        component: () => import('../views/teacher/GradingSurvey.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Survey',
          collapse: false,
        },
      },
      {
        path: '/instructor/:id/survey/:type/:survey_id/:student_id',
        name: 'Instructor Survey Student',
        component: () => import('../views/teacher/GradingSurveyStudent.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Survey',
          collapse: false,
        },
      },
      {
        path: '/instructor/grading/:id',
        name: 'Instructor Grading Course',
        component: () => import('../views/teacher/GradingAssessment.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Grading',
          collapse: false,
        },
      },
      {
        path: '/instructor/grading/:course_id/assessment/:id',
        name: 'Instructor Grading Assessment',
        component: () => import('../views/teacher/GradingAssessmentStudent.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Grading',
          collapse: false,
        },
      },
      {
        path: '/instructor/grading/:course_id/assessment/:id/:user_assessment_id/user/:user_id',
        name: 'Instructor Grading Assessment User',
        component: () => import('../views/teacher/GradingStudent.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Grading',
          collapse: false,
        },
      },
      {
        path: '/instructor/enrollment',
        name: 'Instructor Enrollment',
        component: () => import('../views/teacher/Enrollment.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Enrollment',
          collapse: false,
        },
      },
      {
        path: '/instructor/enrollment/:id',
        name: 'Instructor Enrollment Student',
        component: () => import('../views/teacher/EnrollmentStudent.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Enrollment',
          collapse: false,
        },
      },
      {
        path: '/instructor/inbox',
        name: 'Instructor Inbox',
        component: () => import('../views/teacher/Inbox.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Inbox',
          collapse: false,
        },
      },
      
      {
        path: '/instructor/student-outputs',
        name: 'Instructor Student Outputs',
        component: () => import('../views/teacher/StudentOutput.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Student Outputs',
          collapse: false,
        },
      },

      {
        path: '/instructor/student-outputs/:class_code/:uuid',
        name: 'Instructor Student Outputs Overview',
        component: () => import('../views/teacher/StudentOutputOverview.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Student Outputs Overview',
          collapse: false,
        },
      },

      {
        path: '/instructor/reporting',
        name: 'Instructor Reporting',
        component: () => import('../views/teacher/Reporting.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Reporting',
          collapse: false,
        },
      },

      {
        path: '/instructor/trash',
        name: 'Instructor Trash',
        component: () => import('../views/teacher/Trash.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Trash',
          collapse: false,
        },
      },
      // {
      //   path: '/teacher/my-courses/:id',
      //   name: 'Teacher Course Details',
      //   component: () => import('../views/teacher/CourseDetails.vue'),
      // },
      // {
      //   path: '/teacher/student-outputs',
      //   name: 'Teacher Student Outputs',
      //   component: () => import('../views/teacher/StudentOutput.vue'),
      // },
      // {
      //   path: '/teacher/check/:type/:id',
      //   name: 'Teacher Check Assessment',
      //   component: () => import('../views/teacher/CheckAssessment.vue'),
      // },
      {
        path: '/instructor/announcements',
        name: 'Instructor Announcements',
        component: () => import('../views/teacher/Announcements.vue'),
        meta: { 
          middleware: [middleware],
          parent: 'Instructor Announcements',
          collapse: false,
        },
      },
      {
        path: '/instructor/account-settings',
        name: 'Instructor Settings',
        component: () => import('../views/teacher/AccountSettings.vue'),
        redirect: {name: 'Instructor Account Profile'},
        meta: {
          middleware: [middleware],
          parent: 'Instructor Settings',
          collapse: false
        },
        children: [
          {
            path: 'profile',
            name: 'Instructor Account Profile',
            component: () => import('../views/user/Profile.vue'),
            meta: {
              parent: 'Instructor Settings',
              collapse: false,
              middleware: [middleware],
            },
          },
          {
            path: 'change-password',
            name: 'Instructor Change Password',
            component: () => import('../views/user/ChangePassword.vue'),
            meta: {
              parent: 'Instructor Settings',
              collapse: false,
              middleware: [middleware],
            },
          }
        ]
      },
      // {
      //   path: '/teacher/assessment/:id',
      //   name: 'Teacher Assessment Details',
      //   component: () => import('../views/teacher/AssessmentDetails.vue'),
      // },
      // {
      //   path: '/teacher/ticket',
      //   name: 'Teacher Manage Ticket',
      //   component: () => import('../views/teacher/Ticket.vue'),
      // },
      // {
      //   path: '/teacher/settings',
      //   name: 'Teacher Settings',
      //   component: () => import('../views/teacher/Settings.vue'),
      //   redirect: { name: 'Teacher Account Setting' },
      //   children: [
      //     {
      //       path: 'account',
      //       name: 'Teacher Account Setting',
      //       component: () => import('../views/teacher/Account.vue')
      //     }
      //   ]
      // },
    ]
  }
]